<section class="section social" id='social'>
    <div class="container">
        <div class="section-box">
            <div class="about-title mb-5" data-aos="fade-up" data-aos-duration="1000">
                <h3 class="e-font section-title">
                    <span class='code-font n-section-title'>03.</span> {{"Social.Title" | translate}}
                </h3>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000">
                <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                    <a *ngFor='let social of socials' [innerHTML]="social.title" style="margin-bottom: 4%;margin-right: 2%;" [href]="social.url" target="_blank" class="main-btn" data-aos="fade-up" data-aos-duration="1000">
                    </a>
                </div>
            </div>

        </div>
    </div>
</section>
