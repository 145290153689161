import { Component, OnInit } from '@angular/core';
import { MetadataService } from 'src/app/services/metadata/metadata.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  skills:string[];
  constructor(
    public metadataService: MetadataService
  ) {
    this.skills =  metadataService.metadata['Skills'];
   }

  ngOnInit(): void {
  }

}
