import { Component, OnInit } from '@angular/core';
import { trigger, query, stagger, animate, style, transition } from '@angular/animations'
import { MetadataService } from 'src/app/services/metadata/metadata.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations:[
    trigger("animateFooter", [
      transition(":enter", [
        query("*", [
          style({opacity: 0, transform: "translateY(100%)"}),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({opacity:1, transform: "none"})
            )
          ])
        ])
      ])
    ])
  ]
})
export class FooterComponent implements OnInit {

  phone:string;
  mail:string;
  phoneClick:string;
  mailClick:string;
  constructor(
    public metadataService: MetadataService
  ) {
    this.mail = metadataService.metadata['mail'];
    this.phone = metadataService.metadata['phone'];
    this.mailClick = "mailto:" + metadataService.metadata['mail'];
    this.phoneClick = "tel:" + metadataService.metadata['phone'];
  }
  
  ngOnInit(): void {
  }

}
