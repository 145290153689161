import { Component, OnInit } from '@angular/core';
import { MetadataService } from 'src/app/services/metadata/metadata.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  
  active = 0;
  appreciation:string;
  
  constructor(
    public metadataService: MetadataService
  ) {
    this.appreciation =  metadataService.metadata['appreciation'];
  }

  ngOnInit(): void {
  }

}
