<nav [@animateMenu] class="navbar main-navbar on-top" [class.nav-shadow]='this.pageYPosition>0'>
    <div class="container">
        <ul ngbNav #nav="ngbNav" class="menu-ul">
            <li ngbNavItem >
                <a ngbNavLink (click)='scroll("about")'><span class='nav-number'>01. </span> <span
                        class="underline nav-text"> {{"Header.Item1" | translate}}</span></a>
                <ng-template ngbNavContent>First content</ng-template>
            </li>
            <li ngbNavItem >
                <a ngbNavLink (click)='scroll("jobs")'><span class='nav-number'>02. </span> <span
                        class="underline nav-text"> {{"Header.Item2" | translate}}</span></a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li>
            <li ngbNavItem >
                <a ngbNavLink (click)='scroll("social")'><span class='nav-number'>03. </span> <span
                        class="underline nav-text"> {{"Header.Item4" | translate}}</span></a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li>
            <li ngbNavItem >
                <a ngbNavLink (click)='scroll("contact")'><span class='nav-number'>04. </span> <span
                        class="underline nav-text"> {{"Header.Item5" | translate}}</span></a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li>
            <li>
                <a [href]="resume" target="_blank" class="main-btn cv-btn">
                    {{"Header.cvBtn" | translate}}
                </a>
            </li>
        </ul>
        <div class="menu-wrapper">
            <div [class.animate]='responsiveMenuVisible' (click)='responsiveMenuVisible = !responsiveMenuVisible'
                class="hamburger-menu"></div>
        </div>
        <div class="menu-responsive" [ngStyle]="{'pointer-events': !responsiveMenuVisible ? 'none' : '' }">
            <aside [class.aside-show]='responsiveMenuVisible' [class.nav-shadow]='this.pageYPosition>0' class="on-top">
                <nav>
                    <ol>
                        <li >
                            <a (click)='scroll("about")'>
                                <span>01. </span> {{"Header.Item1" | translate}}
                            </a>
                        </li>
                        <li >
                            <a (click)='scroll("jobs")'>
                                <span>02. </span> {{"Header.Item2" | translate}}
                            </a>
                        </li>
                        <li >
                            <a (click)='scroll("social")'>
                                <span>03. </span> {{"Header.Item4" | translate}}
                            </a>
                        </li>
                        <li >
                            <a (click)='scroll("contact")'>
                                <span>04. </span> {{"Header.Item5" | translate}}
                            </a>
                        </li>
                        <li>
                            <a [href]="resume" target="_blank" class="main-btn cv-btn">
                                {{"Header.cvBtn" | translate}}
                            </a>
                        </li>
                    </ol>
                </nav>
            </aside>
        </div>
    </div>
</nav>