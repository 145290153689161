import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

@Injectable({providedIn:'root'})
export class MetadataService {

  metadata:object;
  constructor(private http:HttpClient ) {
    
   }

   load(){
    return new Promise((fullfilled,rejected)=>{
      this.http.get('assets/metadata/metadata.json').subscribe({
        next:(data)=>{
          this.metadata = data;
          fullfilled(true);
        },
        error:(error)=>{
          rejected(error);
        }
      })
    });
   }
}
