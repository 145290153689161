import { Component, OnInit } from '@angular/core';
import { MetadataService } from 'src/app/services/metadata/metadata.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  phone:string;
  mail:string;
  constructor(
    public metadataService: MetadataService
  ) {
    this.mail = "mailto:" + metadataService.metadata['mail'];
    this.phone = "tel:" + metadataService.metadata['phone'];
  }

  ngOnInit(): void {
  }

}
