import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MetadataService } from 'src/app/services/metadata/metadata.service';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent {

  socials: object;
  constructor(
    public metadataService: MetadataService
  ) {
    this.socials = metadataService.metadata['Socials'];
  }
}
