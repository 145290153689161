import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Title, Meta } from '@angular/platform-browser';
import { LanguageService } from './services/language/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'priyanshu-mittal-portfolio';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private languageService: LanguageService
  ) {
  }
  ngOnInit(): void {

    this.languageService.initLanguage()

    this.titleService.setTitle("Priyanshu Mittal | Full Stack Developer | Angular Developer | JavaScript Developer | Software Engineer");
    this.metaService.addTags([
      { name: 'keywords', content: 'Frontend Developer, Full Stack Developer , Angular Stack Developer , Javascript Developer , Software Engineer, software , developer, Data structures' },
      { name: 'description', content: 'As a software engineer with expertise in Angular and JavaScript Development, I have a comprehensive understanding of Data structure and algorithms. My strong foundation in JavaScript allows me to effectively work with front-end technologies such as Angular. Familiar with object oriented technologies like Java.' },
    ]);
    
    AOS.init();

  }
}
