<section class="section about" id='about'>
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>01.</span> {{"AboutMe.Title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor='let text of "AboutMe.Paragraphs" | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                        </p>
                        <ul class="skills-list" data-aos="fade-up">
                            <li *ngFor='let skill of skills' class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline"  [innerHTML]="skill"> </span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
