<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-md-block">
            <a [href]="phoneClick" target='_blank' [innerHTML]="phone"></a>
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a [href]="mailClick" target='_blank' [innerHTML]="mail"></a>
        </div>
    </div>

    <div class="footer-credits text-center" >
            <div>Built with Angular by Priyanshu Mittal</div>
        <a target="_blank" rel="nofollow noopener noreferrer">
            <div class="mt-2">Designed by Brittany Chiang & Jośe Andreś</div>
        </a>
    </div>

</footer>