<section class="section banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
                    <h1 [innerHTML]='"Banner.Pretitle" | translate'></h1>
                </div>
                <div class='banner-title'>
                    <h2 [innerHTML]='"Banner.Name" | translate'></h2>
                </div>
                <div style="margin-top: 1%;">
                    <h1 [innerHTML]='"Banner.Posttitle" | translate'></h1>
                </div>
                <div class='banner-title'>
                    <h3 *ngFor='let title of titles' [innerHTML]="title"></h3>
                </div>
                <div class='banner-description'>
                    <p [innerHTML]='"Banner.Description" | translate' class="mt-4"></p>
                </div>
            </div>
        </div>
    </div>
</section>
